
<div *ngIf="hasFilter" class="list-header">
  <div #filter shipment-queue-filter-need-routing></div>
</div>

<nz-table
  #nzTable nzBordered="true"
  [nzScroll]="{ x: '100vw', y: null }"
  [nzData]="listData" nzSize="small"
  [nzTotal]="totalCount" [nzFrontPagination]="false"
  [nzShowPagination]="totalCount > listData.length"
  [nzPageSize]="limit" [nzPageIndex]="pageIndex"
  [nzLoading]="isLoading"
  [nzLoadingIndicator]="tplLoading"
  [nzNoResult]="tplNoData"
  [nzSize]="'default'"
  (nzPageIndexChange)="onDataListPageChanged($event)">
  <ng-template #tplNoData>
    <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
  </ng-template>
  <ng-template #tplLoading>
    <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  </ng-template>
  <thead (nzSortOrderChange)="onSortOrderChange($event)">
    <tr>
      <th nzWidth="160px">Shipment ID</th>
      <th nzWidth="160px">Order ID</th>
      <th nzWidth="90px">Type</th>
      <th nzWidth="80px">Transit</th>
      <th>Customer</th>
      <th>Pickup Location</th>
      <th nzWidth="200px">Pickup Date & Time</th>
      <th>Delivery Location</th>
      <th nzWidth="200px">Delivery Date & Time</th>
      <th>Status</th>
      <th *ngIf="hasAction" [nzRight]="true">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of listData; let i = index">
      <td>
        <a [routerLink]="getRouterLink(item)" target="_blank">{{showShipmentCode(item)}}</a>
      </td>
      <td>
        <a [routerLink]="getRouterLink(item)" target="_blank">{{showOrderCode(item.order ?? {})}}</a>
        <a *ngIf="isOrderful(item)" style="color: inherit; margin-left: 10px;" (click)="clickOrderful(item)" nz-tooltip
          [nzTooltipTitle]="'Orderful'">
          <img src="assets/img/orderful-logo.png" style="width: 16px; height: 15px" />
        </a>
      </td>
      <td>{{item.shipmentType}}</td>
      <td>{{item.transitType}}</td>
      <td>{{item.client?.name}}</td>
      <td>
        <div>{{item.pickCity}}</div>
        <div class="addr-street">{{item.pickStreet}}</div>
      </td>
      <td style="text-align: center">
        <div style="white-space: pre;">{{item.pickDt}}</div>
        <div *ngIf="item.warnPickAppt" class="danger">
          {{item.warnPickAppt}}
          <i nz-icon nzType="edit" nzTheme="outline" class="clickable"
            nz-tooltip nzTooltipTitle="Update Appointment"
            (click)="onBtnUpdatePickAppt(item)"></i>
        </div>
        <div *ngIf="item.warnPickWindow" class="danger">
          {{item.warnPickWindow}}
          <i nz-icon nzType="edit" nzTheme="outline" class="clickable"
            nz-tooltip nzTooltipTitle="Update Windows"
            (click)="onBtnUpdatePickWindow(item)"></i>
        </div>
      </td>
      <td>
        <div>{{item.dropCity}}</div>
        <div class="addr-street">{{item.dropStreet}}</div>
      </td>
      <td style="text-align: center">
        <div style="white-space: pre;">{{item.dropDt}}</div>
        <div *ngIf="item.warnDropAppt" class="danger">
          {{item.warnDropAppt}}
          <i nz-icon nzType="edit" nzTheme="outline" class="clickable"
            nz-tooltip nzTooltipTitle="Update Appointment"
            (click)="onBtnUpdateDropAppt(item)"></i>
        </div>
        <div *ngIf="item.warnDropWindow" class="danger">
          {{item.warnDropWindow}}
          <i nz-icon nzType="edit" nzTheme="outline" class="clickable"
            nz-tooltip nzTooltipTitle="Update Windows"
            (click)="onBtnUpdateDropWindow(item)"></i>
        </div>
      </td>
      <td>
        <div class="order-status status-{{item.status}}">{{getStatusShipment(item.status)}}</div>
      </td>
      <td *ngIf="hasAction" [nzRight]="true">
        <a *ngFor="let action of item.actions" (click)="action.handler(item)">
          {{action.name}}
        </a>
      </td>
    </tr>
  </tbody>
</nz-table>
